<template>
  <div class="basic-information-page page-info-content">
    <h3 class="title">
      店铺信息
    </h3>
    <div class="describe-box">
      <div class="item">
        <span class="label">公司名称</span>
        <p>{{ storeBasicInformation.cmpName }}</p>
      </div>
      <div class="item">
        <span class="label">店铺LOGO</span>
        <img v-if="storeBasicInformation.shopLogo" class="logo" :src="$fileUrl+storeBasicInformation.shopLogo" alt="logo">
        <img v-else class="logo" src="@/assets/image/store_logo.png" alt="">
      </div>
      <div class="item">
        <span class="label">业务类型</span>
        <p>{{ storeBasicInformation.type }}</p>
      </div>
      <div class="item">
        <span class="label">主营产品</span>
        <p>{{ storeBasicInformation.mainProduct }}</p>
      </div>
      <div class="item">
        <span class="label">经营地址</span>
        <p>{{ storeBasicInformation.placeProvinceName }}{{ storeBasicInformation.placeCityName }}{{ storeBasicInformation.placeRegionName }}{{ storeBasicInformation.placeDesc }}</p>
      </div>
      <div class="item">
        <span class="label">公司简介</span>
        <p>{{ storeBasicInformation.cmpProfile }}</p>
      </div>
      <div class="item">
        <span class="label">公司实拍</span>
        <img v-if="storeBasicInformation.cmpRealPhotoUrl" class="real-image" :src="$fileUrl+storeBasicInformation.cmpRealPhotoUrl" alt="">
        <img v-else class="real-image" src="@/assets/image/default_shop.png" alt="">
      </div>
      <h3 class="title gap">
        联系人
      </h3>
      <div class="item">
        <span class="label">联系人</span>
        <p>{{ storeBasicInformation.contactPerson }}</p>
      </div>
      <div class="item">
        <span class="label">联系方式</span>
        <p>{{ storeBasicInformation.contactPhone }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { queryShopDesc } from '@/api/shoppingMall'
export default {
  data() {
    return {
      storeBasicInformation: {}
    }
  },
  created() {
    if (this.$route.query.id) {
      queryShopDesc(this.$route.query.id, res => {
        this.storeBasicInformation = { ...res.data }
        this.storeBasicInformation.type = ''
        const businessType = this.$store.getters.getDictionaryItem('SHOP_BUSINESS_TYPE') || []
        this.storeBasicInformation.tradeShopBusinessTypeList.forEach((val, index) => {
          const obj = businessType.find((item) => item.dictId === val.type) || {}
          this.storeBasicInformation.type += (index > 0 ? '、' : '') + obj.dictName
        })
      })
    }
  }
}
</script>

  <style lang="scss" scoped>
  .basic-information-page {
    // width: 100%;
    .describe-box {
      padding-right: 96px;
      .item {
        display: flex;
        align-items: flex-start;
        font-size: 14px;
        color: #000000;
        padding-top: 24px;
        .real-image {
          height: 148px;
          width: 198px;
        }
        .logo {
          height: 120px;
          width: 120px;
        }
        .label {
          flex: 0 0 92px;
          padding-right: 16px;
          color: #666;
          text-align: right;
        }
      }
    }
    .title {
      height: 20px;
      line-height: 20px;
      color: #000000;
      font-size: 20px;
      font-weight: 500;
      padding-left: 8px;
      border-left: 4px solid #0172eb;
      margin-bottom: 8px;
    }
    .gap {
      margin-top: 56px;
    }
  }
  </style>

